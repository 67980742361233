import App from './App';
import 'core-js/actual';
import React from 'react';
import 'core-js/actual/set';
import './styles/global.scss';
import 'core-js/actual/promise';
import 'core-js/actual/iterator';
import 'core-js/actual/array/from';
import 'core-js/features/array/flat';
import 'core-js/actual/array/flat-map';
import ReactDOM from 'react-dom/client';
import 'core-js/actual/structured-clone';
import { HelmetProvider } from 'react-helmet-async';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </React.StrictMode>
);
