import React from 'react';
import '../../styles/components/ui/SpinnerLoader.scss';

function SpinnerLoader() {
    return (
        <div className="SpinnerLoader">
            <div className="loader"></div>
        </div>
    )
}

export default SpinnerLoader